<template>
    <PageDetails>
        <div class="flex flex-col flex-1 min-h-full">
            <!-- Navigation, DisplayDate/Refresh and Filter slot-->
            <div
                class="flex flex-col justify-between gap-4 mb-4 lg:flex-row lg:items-center lg:gap-8"
            >
                <!-- Responsive div to display the Navigation and DisplayDate/Refresh -->
                <div class="flex flex-col-reverse gap-2 lg:flex-row lg:gap-4">
                    <!-- Calendar Navigation buttons -->
                    <div class="flex items-center gap-4">
                        <select
                            @change="updateCalendar"
                            v-model="view"
                            class="block py-2 pl-3 pr-10 text-base font-medium text-gray-600 placeholder-gray-400 transition duration-100 ease-in-out border-gray-300 rounded hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:border-gray-300"
                        >
                            <option
                                v-for="viewOption in viewOptions"
                                :key="viewOption.value"
                                :selected="viewOption.value === view"
                                :value="viewOption.value"
                            >
                                {{ viewOption.text }}
                            </option>
                        </select>

                        <GPButton
                            @click.native="showToday"
                            color="outline-gray"
                        >
                            Today
                        </GPButton>

                        <GPButton
                            v-if="view != 'month'"
                            @click.native="prevBlock"
                            color="outline-gray"
                        >
                            <ChevronLeftIcon />
                        </GPButton>

                        <GPButton
                            v-if="view != 'month'"
                            @click.native="nextBlock"
                            color="outline-gray"
                        >
                            <ChevronRightIcon />
                        </GPButton>
                    </div>

                    <!-- DisplayDate/Refresh, sits on top in mobile view -->
                    <div class="flex items-center gap-4">
                        <div class="flex gap-4">
                            <div
                                class="col-span-4 font-bold text-center lg:col-span-1"
                            >
                                <select
                                    v-model="selectedMonth"
                                    @change="onMonthChange"
                                >
                                    <option
                                        v-for="(month, index) in months"
                                        :key="index"
                                        :value="month.value"
                                        >{{ month.name }}</option
                                    >
                                </select>
                            </div>
                            <button
                                @click="updateCalendar"
                                title="Refresh calendar"
                            >
                                <RefreshIcon />
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Filters slot - takes 100% of width when mobile -->
                <div class="flex items-center justify-end flex-1 w-full gap-4">
                    <slot name="filters"></slot>
                </div>
            </div>

            <!-- Status keys, displays the availableStatusKeys prop and wraps -->
            <div class="flex justify-between flex-1 w-full">
                <div class="flex flex-wrap items-center gap-2 mb-4">
                    <gig-status-checkbox
                        ref="statuscheckbox"
                        :venueFilter="selectedVenue"
                        :statuses="availableStatusKeys"
                        v-on:statusFiltersUpdated="
                            $emit('statusFiltered', $event)
                        "
                    />
                </div>
                <div class="flex items-center justify-end">
                    <slot name="additionalFilters"></slot>
                    <slot name="weekStartDay"></slot>
                </div>
            </div>

            <!-- Only show if venue -->
            <div v-show="isVenue && isGenre" class="mb-6">
                <strong>Custom Tags</strong>
                <div class="flex items-center mt-2 space-x-3">
                    <div class="w-80">
                        <TRichSelect
                            multiple
                            v-model="setTagFilters"
                            ref="iconselect"
                            class="col-span-12 cursor-pointer lg:col-span-5"
                            :close-on-select="false"
                            valueAttribute="id"
                            textAttribute="name"
                            :options="venue_custom_tags"
                            placeholder="Filter by custom tag"
                        >
                            <template
                                slot="option"
                                slot-scope="{
                                    index,
                                    isHighlighted,
                                    isSelected,
                                    className,
                                    option,
                                }"
                            >
                                <div class="flex gap-4 py-2">
                                    <component
                                        :is="icons[option.raw.icon]"
                                        class="ml-3"
                                    />
                                    {{ option.raw.name }}
                                </div>
                            </template>
                        </TRichSelect>
                    </div>
                    <div v-show="hasTagAccess">
                        <button
                            @click="openCreateTagModal()"
                            class="flex items-center justify-center px-4 py-1 text-sm font-bold text-white bg-gp_pink-default-500 hover:bg-gp_pink-default-400 rounded-3xl"
                        >
                            <component
                                :is="icons['PlusIcon']"
                                class="w-5 h-5 mr-1"
                            />
                            <span class="hidden md:block">New Tag</span>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Calendar -->
            <div
                ref="calendarContainer"
                class="z-0 flex flex-col flex-1 w-full"
            >
                <Calendar
                    ref="tuiCalendar"
                    :style="getCalendarStyle"
                    :useCreationPopup="false"
                    :useDetailPopup="false"
                    :usageStatistics="false"
                    :schedules="schedules"
                    :view="view"
                    :taskView="false"
                    :scheduleView="scheduleView"
                    :calendars="getCalendarList"
                    @beforeCreateSchedule="beforeCreateSchedule"
                    @clickSchedule="clickSchedule"
                    :isReadOnly="isReadOnly"
                />
            </div>
        </div>
        <CreateCustomGigTag
            ref="createGigTag"
            v-on:addedTag="newCreatedTag($event)"
        />
    </PageDetails>
</template>

<script>
import PageDetails from "../ui/wrappers/PageDetails.vue";
import GPButton from "../ui/buttons/Button.vue";
import GigStatusCheckbox from "../iconsets/GigStatusCheckbox.vue";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    RefreshIcon,
} from "@vue-hero-icons/outline";
import { TRichSelect } from "vue-tailwind/dist/components";
import { Calendar } from "@toast-ui/vue-calendar";
import "tui-calendar/dist/tui-calendar.css";
import formatDate from "../../utils/format-date";

import * as icons from "@vue-hero-icons/outline";
import CreateCustomGigTag from "../../components/modal/venues/CreateCustomGigTag.vue";
import VenueCalendar from "../../views/Calendar/VenueCalendar.vue";
import client from "@/utils/client.js";
import { mapState } from "vuex";

//const CURRENT_DATE = "CALENDAR_CURRENT_DATE";

export default {
    components: {
        PageDetails,
        GPButton,
        GigStatusCheckbox,
        ChevronLeftIcon,
        ChevronRightIcon,
        RefreshIcon,
        TRichSelect,
        Calendar,
        CreateCustomGigTag,
        VenueCalendar,
    },
    props: {
        isReadOnly: {
            type: Boolean,
            required: false,
        },
        calendarType: {
            type: String,
            required: true,
        },
        viewOptions: {
            type: Array,
            required: true,
        },
        weekStartDay: {
            type: Boolean,
            default: false,
        },
        availableStatusKeys: {
            type: Array,
            required: true,
        },
        schedules: {
            type: Array,
            required: true,
        },
        scheduleView: {
            type: Array,
            required: false,
            default: () => ["time"],
        },
        selectedVenue: {
            required: false,
        },
        triggerUpdate: {
            type: [String, Number, Boolean],
            default: null,
        },
    },
    emits: ["update", "selected", "refresh", "create"],
    data() {
        return {
            view: "month",
            tooltip: false,
            calendar: null,
            currentDate: null,
            startDateRange: null,
            endDateRange: null,
            containerHeight: 700,
            currentMonth: null,
            statusFilters: [],
            icons: icons,
            setTagFilters: null,
            venue_custom_tags: [],
            selectedMonth: "2023-01",
            months: [],
            localWeekStartDay: this.weekStartDay
        };
    },
    computed: {
        ...mapState({
            userRoles: (state) => state.user.rolesPermissionsSlugs,
            userData: (state) => state.user.userData,
        }),
        hasTagAccess() {
            if (this.userRoles && this.userRoles.length > 0) {
                return this.userRoles[0] == "full-access";
            } else {
                return null;
            }
        }, 
        isGenre() {
          if (!this.userData) return false;
            return this.userData.email.includes("genremusic");
        },
        getStatusKey() {
            return (status, index) => `${index}-${status.type}`;
        },
        getCalendarStyle() {
            return `height: ${this.containerHeight}px`;
        },
        isVenue() {
            return localStorage.initialRoute == "venue";
        },
        getCalendarList() {
            return [
                {
                    id: 0,
                    name: this.calendarType,
                    color: "#ffffff",
                    bgColor: "#9e5fff",
                    dragBgColor: "#ff4040",
                    borderColor: "#fff",
                },
            ];
        },
        getCalendarDate() {
            return this.currentDate
                ? this.formatDate(this.currentDate, "MMMM yyyy")
                : "";
        },
    },
    watch: {
        setTagFilters(val) {
            this.$emit("tagFiltered", val);
        },
        weekStartDay: {
            handler() {
                this.updateCalendar();
            },
            deep: true,
        },
    }, 
    methods: {
        formatDate,
        newCreatedTag(event) {
            this.venue_custom_tags.push(event);
        },
        openCreateTagModal() {
            this.$refs.createGigTag.modalOpen = true;
        },
        showToday() {
            this.$refs.tuiCalendar.invoke("today");
            localStorage.setItem(
                "CURRENT_DATE",
                this.$refs.tuiCalendar.invoke("getDate")._date
            );
            this.updateCalendar();
        },
        nextBlock() {
            this.$refs.tuiCalendar.invoke("next");
            localStorage.setItem(
                "CURRENT_DATE",
                this.$refs.tuiCalendar.invoke("getDate")._date
            );
            this.updateCalendar();
        },
        prevBlock() {
            this.$refs.tuiCalendar.invoke("prev");
            localStorage.setItem(
                "CURRENT_DATE",
                this.$refs.tuiCalendar.invoke("getDate")._date
            );
            this.updateCalendar();
        },
        onMonthChange() { 
            if (typeof this.selectedMonth === "string") {
                const [year, month] = this.selectedMonth.split("-").map(Number);
                const date = new Date(year, month - 1, 15); // Simplified date setting
                this.$refs.tuiCalendar.invoke("setDate", date);
                localStorage.setItem("CURRENT_DATE", date.toISOString()); // More efficient date storage
                this.updateCalendar();
            }
        },
        allVenueFilterSelected() {
            this.$refs.statuscheckbox.allSelected();
        },
        selectVenueFilterSelected() {

            if (this.$refs.statuscheckbox)
                this.$refs.statuscheckbox.venueSelected();
        },
        updateCalendar() {
            this.localWeekStartDay = this.userData.calendar_start_day === 1;
            this.updateDates();
            this.$emit("update", {
                view: this.view,
                currentDate: this.currentDate,
                startDateRange: this.startDateRange,
                endDateRange: this.endDateRange,
            });

            let tui = this.$refs.tuiCalendar;
            tui.invoke("setOptions", {
                month: {
                    startDayOfWeek: this.localWeekStartDay ? 1 : 0,
                },
                week: {
                    startDayOfWeek: this.localWeekStartDay ? 1 : 0,
                },
            });
            setInterval(function() {
                tui.invoke("render");
            }, 20000);

            // Wait for the calendar to update before setting the selected month
            this.$nextTick(() => {
                let currentDate = new Date(
                    this.$refs.tuiCalendar.invoke("getDate")._date
                );
                this.selectedMonth = this.formatDate(currentDate, "yyyy-MM");
            });
        },
        updateDates() {
            // Check if 'CURRENT_DATE' in localStorage is null
            if (localStorage.getItem("CURRENT_DATE") === null) {
                localStorage.setItem(
                    "CURRENT_DATE",
                    this.$refs.tuiCalendar.invoke("getDate")._date
                );
            }
            this.startDateRange = this.$refs.tuiCalendar.invoke(
                "getDateRangeStart"
            )._date;
            this.endDateRange = this.$refs.tuiCalendar.invoke(
                "getDateRangeEnd"
            )._date;

            let $middleDate = new Date(
                this.endDateRange -
                    (this.endDateRange - this.startDateRange) / 2
            );
            this.selectedMonth = new Date(
                this.$refs.tuiCalendar.invoke("getDate")._date
            ).getMonth();
            let $month = this.formatDate($middleDate, "MMMM");

            //localStorage.setItem('CURRENT_DATE', this.$refs.tuiCalendar.invoke('getDate')._date);
            this.currentDate = $middleDate;
            this.currentMonth = $month;
        },
        generateMonthsList() {
            this.months = [];
            let currentDate = new Date();
            for (let i = -16; i <= 16; i++) {
                let date = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth() + i,
                    1
                );
                this.months.push({
                    name:
                        date.toLocaleString("default", { month: "long" }) +
                        " " +
                        date.getFullYear(),
                    value:
                        date.getFullYear() +
                        "-" +
                        String(date.getMonth() + 1).padStart(2, "0"),
                });
            }
        },
        clickSchedule(event) {
            const schedule = event.schedule;
            this.$emit("selected", schedule);
        },
        beforeCreateSchedule(event) {
            this.$emit("create", event); 
        },
        getCalendarContainerHeight() {
            const currHeight = this.$refs.calendarContainer.clientHeight;
            if (!this.containerHeight || this.containerHeight < currHeight) {
                this.containerHeight = currHeight;
                this.$refs.tuiCalendar.invoke("render");
            }
        },
        onRefresh() {
            //this.$emit('refresh');
        },
    },
    mounted() {
        // is mobile
        let isMobile = window.matchMedia("(max-width: 700px)");
        this.$store.dispatch(
            "setCalendarStartDay",
            this.userData.calendar_start_day === 1 ? true : false
        );

        if (isMobile.matches) {
            this.view = "day";
        }

        if (localStorage.initialRoute == "venue") {
            client
                .get("custom-tags")
                .then(
                    (response) => (this.venue_custom_tags = response.data.data)
                );
        }

        // init calendar container height
        this.getCalendarContainerHeight();

        window.addEventListener("resize", this.getCalendarContainerHeight);
        // init dates
        //this.updateDates();
        //if(this.calendarType.toLowerCase() === 'gigs'){
        // trigger an initial 'update event'
        //this.updateCalendar()
        //}
        let storedDate = localStorage.getItem("CURRENT_DATE");
        if (storedDate) {
            this.$refs.tuiCalendar.invoke("setDate", new Date(storedDate));
            this.currentDate = new Date(storedDate);
            this.updateCalendar();
        } else {
            const today = new Date();
            localStorage.setItem("CURRENT_DATE", today.toISOString()); // Save the current date
            this.$refs.tuiCalendar.invoke("setDate", today);
            this.currentDate = today;
            this.updateCalendar();
        }
        this.generateMonthsList();

        //this.onMonthChange();
        //this.updateCalendar();
    },
    destroyed() {
        window.removeEventListener("resize", this.getCalendarContainerHeight);
    }, 
};
</script>

<style>
.tui-full-calendar-weekday-schedule[style*="16, 185, 129"]
    .tui-full-calendar-weekday-schedule-title,
.tui-full-calendar-weekday-schedule[style*="180, 83, 9"]
    .tui-full-calendar-weekday-schedule-title {
    color: white !important;
}
</style>
